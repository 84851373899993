import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/header";

import HomePage from "./pages/homePage";
import OTPPage from "./pages/otpPage";
import PhotoPuzzlePage from "./pages/photoPuzzlePage";
import PuzzleCentralPage from "./pages/puzzleCentralPage";
import FAQsPage from "./pages/faqsPage";
import VenuePage from "./pages/venuePage";
import GiftsPage from "./pages/giftsPage";
import AccommodationPage from "./pages/accommodationPage";
import UKPage from "./pages/ukTripPage";
import STDPage from "./pages/stdPage";
import TextOptInPage from "./pages/textOptInPage";
import BookPuzzlePage from "./pages/bookPuzzlePage";
import FoodPuzzlePage from "./pages/foodPuzzlePage";
import MixedPuzzlePage from "./pages/mixedPuzzlePage";
import WeddingDayPage from "./pages/weddingDayPage";
import WeddingWeekendPage from "./pages/weddingWeekendPage";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Header />
          <Routes>
            {/* Dear Puzzle Solver, viewing these hidden routes is not the intended way to solve the puzzle. 
            If you view these hidden pages, you will likely spoil the solutions. If you wish to solve the puzzle the intended 
            way, take a close look at the save the date picture we sent, as that will lead you through to these pages organically.*/}
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/otp" element={<OTPPage />} />
            <Route exact path="/photo-puzzle" element={<PhotoPuzzlePage />} />
            <Route exact path="/book-puzzle" element={<BookPuzzlePage />} />
            <Route exact path="/food-puzzle" element={<FoodPuzzlePage />} />
            <Route exact path="/mixed-puzzle" element={<MixedPuzzlePage />} />
            <Route exact path="/❤️" element={<PuzzleCentralPage />} />
            <Route exact path="/heart" element={<PuzzleCentralPage />} />
            <Route exact path="/love" element={<PuzzleCentralPage />} />
            <Route exact path="/faqs" element={<FAQsPage />} />
            <Route exact path="/gifts" element={<GiftsPage />} />
            <Route exact path="/venue" element={<VenuePage />} />
            <Route exact path="/accomm" element={<AccommodationPage />} />
            <Route exact path="/uk-trip" element={<UKPage />} />
            <Route exact path="/std" element={<STDPage />} />
            <Route exact path="/optin" element={<TextOptInPage />} />
            <Route exact path="/wedding-day" element={<WeddingDayPage />} />
            <Route
              exact
              path="/wedding-weekend"
              element={<WeddingWeekendPage />}
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
