import React, { useState } from "react";

export default function TripleInput(props) {
  const [values, setValues] = useState([0, 0, 0]);

  const handleChange = (index, event) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue) && newValue >= 0 && newValue <= 255) {
      const newValues = [...values];
      newValues[index] = newValue;
      setValues(newValues);
      props.onChange(newValues);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>RGB( </span>
      <input
        type="text"
        value={values[0]}
        onChange={(e) => handleChange(0, e)}
        maxLength="3"
        style={{
          width: "60px",
          textAlign: "center",
          borderTop: "0px solid",
          borderLeft: "0px solid",
          borderRight: "0px solid",
          borderBottom: "1px solid",
        }}
      />
      <span>,</span>
      <input
        type="text"
        value={values[1]}
        onChange={(e) => handleChange(1, e)}
        maxLength="3"
        style={{
          width: "60px",
          textAlign: "center",
          borderTop: "0px solid",
          borderLeft: "0px solid",
          borderRight: "0px solid",
          borderBottom: "1px solid",
        }}
      />
      <span>,</span>
      <input
        type="text"
        value={values[2]}
        onChange={(e) => handleChange(2, e)}
        maxLength="3"
        style={{
          width: "60px",
          textAlign: "center",
          borderTop: "0px solid",
          borderLeft: "0px solid",
          borderRight: "0px solid",
          borderBottom: "1px solid",
        }}
      />
      <span> )</span>
    </div>
  );
}
