import InviteRsvp from "../components/inviteRsvp";
import Invite from "../components/invite";

export default function HomePage() {
  return (
    <main>
      <div className="image-container">
        <img src="/images/kenya_small.jpeg" alt="" />
      </div>
      <div className="main-inner">
        <Invite />
      </div>
      <div className="box-container line-top line-bottom text-small text-center">
        <div className="main-inner">
          <InviteRsvp></InviteRsvp>
        </div>
      </div>
    </main>
  );
}
