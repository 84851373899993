import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import mixedAnswers from "./mixed.json";

const ItemTypes = {
  BOOK: "book",
  FOOD: "food",
  NONE: "none",
};

const Tile = ({ text, type, index }) => {
  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: { type: type, index: index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        padding: "8px",
        margin: "4px",
        color: "black",
        backgroundColor: type === ItemTypes.BOOK ? "lightgray" : "lightblue",
        textAlign: "center",
      }}
    >
      {text}
    </div>
  );
};

const TableCell = ({ rowIndex, colIndex, tile, moveTile }) => {
  const [, drop] = useDrop({
    accept:
      colIndex === 0
        ? ItemTypes.BOOK
        : colIndex === 1
        ? ItemTypes.FOOD
        : ItemTypes.NONE,
    drop: (item) => moveTile(item, rowIndex, colIndex),
  });

  return (
    <td
      ref={drop}
      style={{
        width: "100px",
        height: "50px",
        border: "1px solid black",
        textAlign: "center",
      }}
    >
      {tile}
    </td>
  );
};

const DragDropTable = ({ onProgress, isSolved }) => {
  const [bookTiles, setBookTiles] = useState(() => {
    return mixedAnswers.answers
      .map((answer) => answer.book)
      .sort(() => Math.random() - 0.5);
  });
  const [foodTiles, setFoodTiles] = useState(() => {
    return mixedAnswers.answers
      .map((answer) => answer.food)
      .sort(() => Math.random() - 0.5);
  });

  const [table, setTable] = useState(
    Array.from({ length: 16 }, () => Array(3).fill(null))
  );

  const moveTile = (tile, rowIndex, colIndex) => {
    if (table[rowIndex][colIndex] !== null) {
      // Don't allow dropping on a cell that already has a tile.
      return;
    }

    let newTiles =
      tile.type === ItemTypes.BOOK ? [...bookTiles] : [...foodTiles];
    if (table[rowIndex][colIndex] !== null) {
      newTiles.push(table[rowIndex][colIndex]);
    }
    const existingTile = newTiles[tile.index];
    newTiles.splice(tile.index, 1);

    const newTable = table.map((row, rIdx) =>
      row.map((cell, cIdx) =>
        rIdx === rowIndex && cIdx === colIndex ? existingTile : cell
      )
    );

    tile.type === ItemTypes.BOOK
      ? setBookTiles(newTiles)
      : setFoodTiles(newTiles);

    if (newTable[rowIndex][0] !== null && newTable[rowIndex][1] != null) {
      const book = newTable[rowIndex][0];
      const food = newTable[rowIndex][1];
      const bookIndex = mixedAnswers.answers
        .map((answer) => answer.book)
        .indexOf(book);
      const foodIndex = mixedAnswers.answers
        .map((answer) => answer.food)
        .indexOf(food);
      if (bookIndex === foodIndex) {
        newTable[rowIndex][2] = mixedAnswers.answers[bookIndex].answer;
      } else {
        newTable[rowIndex][2] = null;
      }
    }

    setTable(newTable);
    onProgress(bookTiles.length + foodTiles.length - 1);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        style={{
          display: "flex",
          gap: "20px",
        }}
      >
        <div>
          <table
            style={{
              borderCollapse: "collapse",
              marginBottom: "20px",
              width: "800px",
            }}
          >
            <thead>
              <tr>
                <th>Book + </th>
                <th>Food =</th>
                <th>Combo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    width: "100px",
                    height: "30px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Charlotte's Web
                </td>
                <td
                  style={{
                    width: "100px",
                    height: "30px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Swiss Chard
                </td>
                <td
                  style={{
                    width: "100px",
                    height: "30px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Chard-lotte's Web
                </td>
                <td
                  style={{
                    width: "50px",
                    height: "30px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                ></td>
              </tr>
              {isSolved
                ? mixedAnswers.answers.map((answer, rowIndex) => (
                    <tr key={rowIndex}>
                      <td
                        style={{
                          width: "100px",
                          height: "50px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {answer.book}
                      </td>
                      <td
                        style={{
                          width: "100px",
                          height: "50px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {answer.food}
                      </td>
                      <td
                        style={{
                          width: "100px",
                          height: "50px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {answer.answer}
                      </td>
                      <td
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      ></td>
                    </tr>
                  ))
                : table.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((tile, colIndex) => (
                        <TableCell
                          key={colIndex}
                          rowIndex={rowIndex}
                          colIndex={colIndex}
                          tile={tile}
                          moveTile={moveTile}
                        />
                      ))}
                      <td
                        style={{
                          width: "50px",
                          height: "50px",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <button
                          className="cta"
                          onClick={() => {
                            const newTable = [...table];
                            newTable[rowIndex] = [null, null, null];
                            setTable(newTable);
                            if (row[0]) {
                              setBookTiles([...bookTiles, row[0]]);
                            }
                            if (row[1]) {
                              setFoodTiles([...foodTiles, row[1]]);
                            }
                          }}
                          style={{
                            cursor: "pointer",
                            padding: "5px",
                          }}
                        >
                          ✕
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>

        <div>
          {!isSolved && (
            <div style={{ display: "flex", marginTop: "80px" }}>
              <div style={{ float: "left" }}>
                {bookTiles.map(
                  (tile, index) =>
                    tile && (
                      <Tile
                        key={"book" + index}
                        text={tile}
                        type={ItemTypes.BOOK}
                        index={index}
                      />
                    )
                )}
              </div>
              <div style={{ float: "right" }}>
                {foodTiles.map(
                  (tile, index) =>
                    tile && (
                      <Tile
                        key={"food" + index}
                        text={tile}
                        type={ItemTypes.FOOD}
                        index={index}
                      />
                    )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </DndProvider>
  );
};

export default DragDropTable;
