import { useState } from "react";

export default function MenuCard({ id, partyMember, onUpdateRsvp, rsvpData }) {
  const adult_menu = {
    starter: [
      {
        id: 0,
        name: "Heritage tomato with burrata, crispy Parma ham and pesto oil",
      },
      {
        id: 1,
        name: "Tian of haggis and clapshot with whisky sauce",
      },
      {
        id: 2,
        name: "Lentil & bean bon bons with tomato fondue and lemon oil (vegetarian)",
      },
    ],
    main: [
      {
        id: 0,
        name: "Corn fed chicken with braised leek, pommes fondant and jus",
      },
      {
        id: 1,
        name: "Pan seared salmon with crushed potatoes, seasonal vegetables and salsa verde",
      },
      {
        id: 2,
        name: "Mushroom tart with roasted king oyster mushrooms, goats cheese and fig balsamic (vegetarian)",
      },
    ],
    dessert: [
      {
        id: 0,
        name: "Chocolate torte with pistachio cream and an oat-and-nut crumble",
      },
      {
        id: 1,
        name: "Apple crumble with vanilla ice cream",
      },
    ],
  };

  const child_menu = {
    starter: [
      { id: 0, name: "Garlic butter doughballs" },
      { id: 1, name: "My baby doesn't need a starter" },
    ],
    main: [
      {
        id: 0,
        name: "Chicken goujons (i.e. chicken tenders) with french fries and peas",
      },
      { id: 1, name: "My baby doesn't need this fancy-ass main course" },
    ],
    dessert: [
      { id: 0, name: "Chocolate brownie with vanilla ice cream" },
      {
        id: 1,
        name: "My baby doesn't need a dessert. They're sweet enough already",
      },
    ],
  };

  const [menuSelection, setMenuSelection] = useState({});

  const onMenuChange = (course, item) => {
    let newMenuSelection = menuSelection;
    newMenuSelection[course] = item;
    setMenuSelection(newMenuSelection);

    partyMember.menu_selection = newMenuSelection;
    rsvpData.menu_selection = newMenuSelection;
    onUpdateRsvp(rsvpData);
  };

  const onDietaryChange = (value) => {
    partyMember.dietary_requirements = value;
    rsvpData.dietary_requirements = value;
    onUpdateRsvp(rsvpData);
  };

  const onSongRequestChange = (value) => {
    partyMember.song_request = value;
    rsvpData.song_request = value;
    onUpdateRsvp(rsvpData);
  };

  return partyMember?.invite_rsvp_status === "yes" ? (
    <div>
      <div
        className="text-container"
        style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
      >
        <p style={{ fontSize: "1.2rem" }}>
          We need a little more information from you. If you have any questions,
          feel free to reach out to Matt or Fiona.
        </p>
      </div>
      <div className="row-top-rem">
        <div
          style={{
            fontSize: "1.7rem",
            marginBottom: "1.5rem",
            marginTop: "1.5rem",
          }}
        >
          To Start*
        </div>
        {(partyMember?.is_child ? child_menu : adult_menu).starter.map(
          (item) => (
            <div className="rsvp-row" key={item.id}>
              <label>{item.name}</label>
              <input
                type="radio"
                name={`starter-${id}`}
                value={item.id}
                onChange={() => onMenuChange("starter", item)}
                style={{
                  width: "auto",
                  margin: "0 0 0 1rem",
                }}
              />
            </div>
          )
        )}
      </div>

      <div className="row-top-rem">
        <div
          style={{
            fontSize: "1.7rem",
            marginBottom: "1.5rem",
            marginTop: "1.5rem",
          }}
        >
          The Main Event*
        </div>
        {(partyMember?.is_child ? child_menu : adult_menu).main.map((item) => (
          <div className="rsvp-row" key={item.id}>
            <label style={{ flex: 1 }}>{item.name}</label>
            <input
              type="radio"
              name={`main-${id}`}
              value={item.id}
              onChange={() => onMenuChange("main", item)}
              style={{
                width: "auto",
                margin: "0 0 0 1rem",
              }}
            />
          </div>
        ))}
      </div>

      <div className="row-top-rem">
        <div
          style={{
            fontSize: "1.7rem",
            marginBottom: "1.5rem",
            marginTop: "1.5rem",
          }}
        >
          Something Sweet*
        </div>
        {(partyMember?.is_child ? child_menu : adult_menu).dessert.map(
          (item) => (
            <div className="rsvp-row" key={item.id}>
              <label>{item.name}</label>
              <input
                type="radio"
                name={`dessert-${id}`}
                value={item.id}
                onChange={() => onMenuChange("dessert", item)}
                style={{
                  width: "auto",
                  margin: "0 0 0 1rem",
                }}
              />
            </div>
          )
        )}
      </div>

      <div className="row-top-rem">
        <div
          style={{
            fontSize: "1.7rem",
            marginBottom: "1.5rem",
            marginTop: "1.5rem",
          }}
        >
          Dietary Requirements
        </div>

        <div key={id + "-dietary"}>
          <input
            type="text"
            onChange={(e) => onDietaryChange(e.target.value)}
            style={{
              fontFamily: "inherit",
              fontSize: "1.2rem",
              padding: "0.5rem",
              width: "100%",
            }}
          />
        </div>
      </div>

      <div className="row-top-rem">
        <div
          style={{
            fontSize: "1.3rem",
            marginBottom: "1.5rem",
            marginTop: "1.5rem",
          }}
        >
          <p>What is one song that will get you on the dance floor?</p>
          <input
            type="text"
            onChange={(e) => onSongRequestChange(e.target.value)}
            style={{
              fontFamily: "inherit",
              fontSize: "1.2rem",
              padding: "0.5rem",
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
