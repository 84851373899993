import MemorySubmission from "../memorySubmission";

export default function PartOne() {
  return (
    <div>
      <h2>Part I: The Past</h2>
      <h2>(Memories)</h2>
      <div className="text-lower" style={{ marginTop: "20px" }}>
        <p className="text-paragraph">Congratulations on completing Part I!</p>
        <p className="text-paragraph">
          After finding each other, Fiona and Matt quickly set out on
          adventures. Small moments together became memories that might be set
          aside or lost, but could be rediscovered and re-lived with a laugh.
        </p>
        <p className="text-paragraph">
          When sitting in the same room, happy memories could be made with a
          joke or a hug. If they were apart, Fiona and Matt shared their lives
          through texts, silly emojis, selfies, even puzzles - anything that
          could conceivably be used to say “I love you”.
        </p>
        <p className="text-paragraph">
          Some memories were made by just the two of them, but their story is
          far from complete unless they're reading the memories made with family
          and friends.
        </p>
        <p className="text-paragraph">
          Please help Matt and Fiona rediscover moments by sharing a memory
          you've held onto. Silly or serious, anything is welcome.
        </p>
        <p className="text-paragraph">
          Be sure to share your memory before continuing to Part II.
        </p>
      </div>

      <div style={{ marginTop: "3rem" }}>
        <MemorySubmission
          memoryLabel="A memory:"
          memoryType="part-one"
          memoryCookieName="memorySubmitted"
        />
      </div>
    </div>
  );
}
