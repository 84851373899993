import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MemorySubmission from "../memorySubmission";

export default function PartThree({ displayPartThree }) {
  const navigate = useNavigate();

  const [mixedPuzzleSolved] = useState(
    localStorage.getItem("mixedPuzzleSolved") ? true : false
  );

  return (
    <div id="part-iii">
      {displayPartThree ? (
        <>
          <h2>Part III: The Future</h2>
          <h2>(Intertwining Memories)</h2>
          <div className="text-lower">
            <p className="text-paragraph">
              Weddings are a celebration of both that which has been and that
              which is yet to be.
            </p>
            <p className="text-paragraph">
              Thinking about their future together, Fiona and Matt are excited
              to see all the ways their ever-intertwining lives will find to
              create new memories.
            </p>
            <p className="text-paragraph">
              On May 24th, 2025, people from their past and present will be
              together to celebrate the future.
            </p>
            <p className="text-paragraph">It's all coming together now.</p>
          </div>
          <div>
            <button
              className="cta"
              style={{ marginTop: "20px" }}
              onClick={() => navigate("/mixed-puzzle")}
            >
              Mixed Puzzle ▶
            </button>
            {mixedPuzzleSolved ? (
              <div
                className="wish-memory-submission text-lower"
                style={{ marginTop: "20px" }}
              >
                <p className="text-paragraph">
                  You did it! You completed the puzzle!
                </p>
                <p className="text-paragraph">
                  We are delighted and overjoyed that we get to have you with us
                  for our big celebration. Everyone invited was chosen because
                  we love intertwining our lives with yours and making memories
                  with you.
                </p>
                <p className="text-paragraph">
                  Throughout our adventure, we've asked a lot of you. You have
                  helped us write our story, metaphorically and literally. You
                  created memories, you shared memories.
                </p>
                <p className="text-paragraph">
                  Over the course of the wedding weekend there will be a wealth
                  of moments, large and small. If we can ask one last thing of
                  you, we'd like you to hold in your mind a wedding moment
                  you're looking forward to. It can be anything: a song you
                  can't wait to dance to, a person you want to hug, a mental
                  picture you can't wait to take.
                </p>
                <p className="text-paragraph">
                  Don't tell us what your moment is, it's just for you to know.
                  When you have it, enter your name and click "share" so we know
                  you're ready to party.
                </p>
                <div style={{ marginTop: "20px" }}>
                  <MemorySubmission
                    memoryLabel="A wish:"
                    memoryType="wish-memory"
                    memoryCookieName="wishMemorySubmitted"
                    memoryOverride="This one's just for you"
                  />
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </>
      ) : (
        <>
          <h2>Part III: ...</h2>
          <br></br>
          <p className="text-lower">The rest is still unwritten...for now.</p>
        </>
      )}
    </div>
  );
}
