import { useState, useEffect } from "react";

import LoadingWheel from "./loadingWheel";
import MemberRsvpForm from "./memberRsvpForm";

import { ApiService } from "../services/apiService";
import { CookieService } from "../services/cookieService";

export default function InviteRsvp() {
  const rsvpCookieName = "inviteRsvpSubmitted";
  const [input, setInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [party, setParty] = useState({});
  const [memberRsvpData, setMemberRsvpData] = useState({});

  const [readyToSubmit, setReadyToSubmit] = useState(false);

  const [submitComplete, setSubmitComplete] = useState(
    CookieService.getCookie(rsvpCookieName) ? true : false
  );
  const [isLoading, setIsLoading] = useState(false);

  const clearCookies = () => {
    CookieService.deleteCookie(rsvpCookieName);
  };

  useEffect(() => {
    // Use this to clear cookies in debug mode
    // clearCookies();
  });

  const updateMemberRsvpData = (memberName, formData) => {
    console.log("updateMemberRsvpData", memberName, formData);
    setMemberRsvpData((prevData) => ({
      ...prevData,
      [memberName]: formData,
    }));

    // Check if we have data for all party members
    const allMembersPresent = party.members?.every(
      (member) => member.name in memberRsvpData || member.name === memberName
    );

    if (allMembersPresent) {
      const updatedData = {
        ...memberRsvpData,
        [memberName]: formData,
      };

      // Validate all entries
      const isValid = party.members.every((member) => {
        const memberData = updatedData[member.name];

        // Check RSVP status exists
        if (!memberData?.invite_rsvp_status) {
          return false;
        }

        // For 'yes' RSVPs, validate menu selections
        if (memberData.invite_rsvp_status === "yes") {
          if (!memberData.menu_selection) {
            return false;
          }

          const hasAllCourses =
            memberData.menu_selection.starter &&
            memberData.menu_selection.main &&
            memberData.menu_selection.dessert;

          if (!hasAllCourses) {
            return false;
          }
        }

        return true;
      });

      setReadyToSubmit(isValid);
    }
  };

  const searchForParty = () => {
    setIsLoading(true);

    ApiService.getParty(input)
      .then((result) => {
        setErrorMessage("");
        setSubmitComplete(false);
        setIsLoading(false);
        if (result && result.status && result.status === "COMPLETE") {
          setErrorMessage(
            "Looks like you already RSVP-ed. Please reach out to Matt or Fiona if you need to change your status."
          );
          setInput("");
          return;
        }
        if (!result || !result.members || result.members.length === 0) {
          setErrorMessage("Could not locate your party. Please try again.");
          setInput("");
          return;
        }

        setParty(result);
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchForParty();
    }
  };

  const submitClicked = () => {
    setIsLoading(true);

    const updatedMembers = party.members.map((member) => ({
      ...member,
      ...memberRsvpData[member.name],
    }));

    const updatedParty = {
      ...party,
      members: updatedMembers,
    };

    ApiService.updateParty(updatedParty)
      .then((result) => {
        setInput("");
        setErrorMessage("");
        setIsLoading(false);

        console.log("result", result);

        if (result && result.status && result.status === "COMPLETE") {
          setSubmitComplete(true);
          setParty({});
          CookieService.setCookie(rsvpCookieName, "true");
          return;
        }

        if (result && result.status && result.status === "ERROR") {
          setErrorMessage(
            "Something went wrong. Please try again later, or reach out to Matt and Fiona directly."
          );
          return;
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  };

  return (
    <div className="rsvp-form">
      {!submitComplete ? (
        <div className="text-container text-small">
          <p>Please let us know if you can make it by responding below.</p>
          <p>
            Enter your name exactly as it appears on your Invite (the message we
            sent you):
          </p>
          <input
            type="text"
            disabled={isLoading}
            value={input}
            onInput={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
          ></input>
          <div className="error">{errorMessage}</div>
        </div>
      ) : (
        <div />
      )}
      {!submitComplete &&
      !isLoading &&
      (!party ||
        (party && !party.members) ||
        (party && party.members && party.members.length === 0)) ? (
        <button className="cta" onClick={searchForParty}>
          Find my RSVP
        </button>
      ) : (
        <div />
      )}
      {isLoading ? <LoadingWheel /> : <div />}
      {!submitComplete &&
        !isLoading &&
        party?.members?.map(
          (item, idx) => (
            <div
              key={idx}
              className="box-container"
              style={{ marginTop: "5.5rem" }}
            >
              <div className="text-italic row-bottom-rem">{item.name}</div>
              <MemberRsvpForm
                partyMember={item}
                onUpdateRsvp={(formData) =>
                  updateMemberRsvpData(item.name, formData)
                }
                rsvpData={item}
              />
            </div>
          ),
          this
        )}
      {!submitComplete &&
      !isLoading &&
      party &&
      party.members &&
      party.members.length > 0 ? (
        <div className="row-top row-bottom">
          <button
            className="cta"
            disabled={!readyToSubmit}
            onClick={submitClicked}
          >
            Submit
          </button>
        </div>
      ) : (
        <div />
      )}
      {submitComplete ? (
        <div>Thank you! We'll let you know if we need anything else.</div>
      ) : (
        <div />
      )}
    </div>
  );
}
