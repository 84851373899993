import EdinburghEatsToggle from "../components/edinburghEatsToggle";
import EdinburghThingsToggle from "../components/edinburghThingsToggle";
import LondonEatsToggle from "../components/londonEatsToggle";
import LondonThingsToggle from "../components/londonThingsToggle";

export default function UKPage() {
  return (
    <main className="line-top line-bottom">
      <div className="main-inner text-center">
        <div className="row-top row-bottom">
          <div className="text-container">
            <h2 className="text-italic">Planning a UK Trip</h2>
            <p className="text-lower">
              If your schedule allows, we recommend coming to the UK at least a
              few days early. This will allow you to overcome any jet lag, and
              also gives you the chance to see some sights.
            </p>
            <br></br>
            <p className="text-lower">
              American citizens will need a passport to fly, AND will need to
              apply for an Electronic Travel Authorization (ETA) (it takes a few
              days).
            </p>
            <a href="https://uk.usembassy.gov/worldwide-travel-alert-new-entry-requirements-for-us-citizens-traveling-or-transiting-through-united-kingdom-airports-effective-january-8-2025/#:~:text=Starting%20January%208%2C%202025%2C%20U.S.,or%20the%20Republic%20of%20Ireland.">
              More details on Electronic Travel Authorization
            </a>
            <br></br>
            <p className="text-lower" style={{ marginTop: "3.5rem" }}>
              We recommend that you fly to either London Heathrow (LHR) or
              Edinburgh (EDI). Both cities have a lot to offer.
            </p>
          </div>
        </div>
      </div>
      <div className="line-top">
        <EdinburghThingsToggle />
        <EdinburghEatsToggle />
        <LondonThingsToggle />
        <LondonEatsToggle />
      </div>
    </main>
  );
}
