export default function GiftsPage() {
  return (
    <main className="line-top line-bottom">
      <div className="main-inner text-center">
        <div className="row-bottom">
          <div className="text-container">
            <h2 className="text-italic">Gifts</h2>
            <p className="text-lower text-paragraph">
              The best gift you can give us is your smiling face on our dance
              floor.
            </p>
            <p className="text-lower text-paragraph">
              For those who don't dance, don't smile, or are otherwise adamant
              about giving a gift, we would be both honored and honoured to have
              you donate to our honeymoon fund.
            </p>
            <p className="text-lower text-paragraph">
              We are planning an adventure to Peru, where we would like to study
              the marital habits of those under the Inca Empire, maybe get some
              tips. We also hope to climb the Rainbow Mountain, and of course,
              visit Paddington. Your generosity will help make our journey
              happen. We've started saving for the way there, but only just
              noticed that we need to also get back.
            </p>
            <div style={{ marginTop: "3.5rem" }}>
              <a
                className="cta no-line"
                href="https://www.paypal.com/paypalme/FiMMcG"
              >
                Contribute
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
