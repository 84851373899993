import { useState } from "react";

import RadioQuestion from "./radioQuestion";
import MenuCard from "./menuCard";

export default function MemberRsvpForm({
  partyMember,
  onUpdateRsvp,
  rsvpData,
}) {
  const [member, setMember] = useState(partyMember);

  const onRsvpOptionChange = (value) => {
    setMember({ ...member, invite_rsvp_status: value });

    rsvpData.invite_rsvp_status = value;
    onUpdateRsvp(rsvpData);
  };

  return (
    <div>
      <RadioQuestion
        idx={member.name}
        question="Will you be joining us for the best day ever?"
        onChange={(e) => onRsvpOptionChange(e.value)}
      ></RadioQuestion>

      <MenuCard
        id={member.name}
        partyMember={member}
        onUpdateRsvp={onUpdateRsvp}
        rsvpData={rsvpData}
      />
    </div>
  );
}
