import { useState } from "react";
import { ApiService } from "../services/apiService";
import LoadingWheel from "./loadingWheel";
import { CookieService } from "../services/cookieService";

export default function MemorySubmission({
  memoryLabel,
  memoryType,
  memoryCookieName,
  memoryOverride,
}) {
  const [nameInput, setNameInput] = useState(
    localStorage.getItem("username" || "")
  );
  const [memoryInput, setMemoryInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitComplete, setSubmitComplete] = useState(
    CookieService.getCookie(memoryCookieName) ? true : false
  );

  const submitClicked = () => {
    handleSubmit();
  };

  const handleSubmit = () => {
    if (nameInput === "" || (!memoryOverride && memoryInput === "")) {
      setErrorMessage("Please enter a name and memory");
      return;
    }

    localStorage.setItem("username", nameInput);

    setErrorMessage("");
    setIsLoading(true);

    ApiService.createMemory({
      name: nameInput,
      memory: memoryInput || memoryOverride,
      memoryType: memoryType,
    })
      .then((result) => {
        setSubmitComplete(false);
        setIsLoading(false);
        if (result.id) {
          setNameInput("");
          setMemoryInput("");
          setSubmitComplete(true);
          CookieService.setCookie(memoryCookieName, true);
        } else {
          setErrorMessage("Something went wrong. Please try again later");
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Something went wrong. Please try again later");
      });
  };

  return (
    <div>
      {!submitComplete && !isLoading ? (
        <div>
          <div>
            <span>Your name:</span>
            <input
              type="text"
              value={nameInput}
              onInput={(e) => setNameInput(e.target.value)}
            ></input>
          </div>
          <div>
            <span>{memoryLabel}</span>
            {memoryOverride ? (
              <div
                className="text-small box-container"
                style={{ marginTop: "1.2rem", marginBottom: "1.2rem" }}
              >
                {memoryOverride}
              </div>
            ) : (
              <textarea
                rows="7"
                cols="50"
                value={memoryInput}
                onInput={(e) => setMemoryInput(e.target.value)}
                style={{ resize: "none" }}
              ></textarea>
            )}
          </div>
          <div>
            <button className="cta" onClick={submitClicked}>
              Share
            </button>
          </div>
        </div>
      ) : (
        <div />
      )}

      {isLoading ? <LoadingWheel /> : <div />}
      {submitComplete ? (
        <div className="text-lower" style={{ fontStyle: "italic" }}>
          Thank you for sharing!
        </div>
      ) : (
        <div />
      )}
      {errorMessage ? (
        <div>There was a problem. Please try again later.</div>
      ) : (
        <div />
      )}
    </div>
  );
}
