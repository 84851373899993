import MemorySubmission from "../memorySubmission";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ApiService } from "../../services/apiService";
import LoadingWheel from "../loadingWheel";

export default function PartTwo({ onSolved }) {
  const navigate = useNavigate();

  const clearLocalStorage = () => {
    localStorage.removeItem("bookPuzzleSolution");
    localStorage.removeItem("foodPuzzleSolution");
    localStorage.removeItem("bookPuzzleSolved");
    localStorage.removeItem("foodPuzzleSolved");
    localStorage.removeItem("partTwoSolved");
    localStorage.removeItem("username");
  };

  const clearMemoryCookies = () => {
    document.cookie =
      "memorySubmitted=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie =
      "foodMemorySubmitted=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie =
      "bookMemorySubmitted=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie =
      "wishMemorySubmitted=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };

  useEffect(() => {
    // Use this to clear cookies in debug mode
    // clearLocalStorage();
    // clearMemoryCookies();
  });

  const [bookSolutionInput, setBookSolutionInput] = useState(
    localStorage.getItem("bookPuzzleSolution") || ""
  );
  const [foodSolutionInput, setFoodSolutionInput] = useState(
    localStorage.getItem("foodPuzzleSolution") || ""
  );
  const [bookPuzzleSolved, setBookPuzzleSolved] = useState(
    localStorage.getItem("bookPuzzleSolved") ? true : false
  );
  const [foodPuzzleSolved, setFoodPuzzleSolved] = useState(
    localStorage.getItem("foodPuzzleSolved") ? true : false
  );
  const [foodIsLoading, setFoodIsLoading] = useState(false);
  const [foodSubmissionErrorMessage, setFoodSubmissionErrorMessage] =
    useState("");
  const [bookIsLoading, setBookIsLoading] = useState(false);
  const [bookSubmissionErrorMessage, setBookSubmissionErrorMessage] =
    useState("");

  const checkPuzzleSolution = async (puzzle, solution) => {
    if (puzzle === "book-puzzle") {
      setBookSubmissionErrorMessage("");
      setBookIsLoading(true);
    } else if (puzzle === "food-puzzle") {
      setFoodSubmissionErrorMessage("");
      setFoodIsLoading(true);
    }

    let mutableBookPuzzleSolved = bookPuzzleSolved;
    let mutableFoodPuzzleSolved = foodPuzzleSolved;

    await ApiService.checkPuzzleSolution(puzzle, solution)
      .then((result) => {
        if (result.status === "correct") {
          localStorage.setItem(result.cookieName, true);

          if (puzzle === "book-puzzle") {
            mutableBookPuzzleSolved = true;
            localStorage.setItem("bookPuzzleSolution", solution);
          } else if (puzzle === "food-puzzle") {
            mutableFoodPuzzleSolved = true;
            localStorage.setItem("foodPuzzleSolution", solution);
          }
        } else {
          if (puzzle === "book-puzzle") {
            setBookSubmissionErrorMessage("Incorrect");
          } else if (puzzle === "food-puzzle") {
            setFoodSubmissionErrorMessage("Incorrect");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setBookIsLoading(false);
        setFoodIsLoading(false);

        setBookPuzzleSolved(mutableBookPuzzleSolved);
        setFoodPuzzleSolved(mutableFoodPuzzleSolved);

        if (mutableBookPuzzleSolved && mutableFoodPuzzleSolved) {
          console.log("partTwoSolved");
          localStorage.setItem("partTwoSolved", true);
          onSolved();
        }
      });
  };

  return (
    <div id="part-ii">
      <h2>Part II: The Present (Intertwining)</h2>
      <div className="text-lower">
        <p className="text-paragraph">
          Ever since Fiona and Matt met, each day has seen an intertwining of
          their lives. Old friends for one became new to the other. A known
          tale, told a thousand times in a friendship, could be once again
          shared for the first time. Someone's smile, beloved for years, could
          brighten a new day.
        </p>
        <p className="text-paragraph">
          As their two worlds came together, Matt and Fiona had the chance to
          see their own experiences through fresh eyes. A stolid British
          tradition might become a fascinating phenomenon. An American custom
          could be discovered with excitement.
        </p>
        <p className="text-paragraph">
          A wedding, of course, is an intertwining itself. A wedding mixes and
          it mingles. Individual parts can come together into brand new wholes.
          People who may otherwise have never crossed paths can share a meal, a
          laugh, a hug. All the while, Fiona and Matt are left with lives more
          deeply intertwined.
        </p>
      </div>
      <div className="line-top line-bottom" style={{ marginTop: "3rem" }}>
        <button className="cta" onClick={() => navigate("/food-puzzle")}>
          Food Puzzle ▶
        </button>
        <div style={{ marginTop: "2rem" }}>
          Solution:
          <input
            type="text"
            disabled={foodPuzzleSolved}
            value={foodSolutionInput}
            onInput={(e) => {
              const cleanInput = e.target.value.toUpperCase();
              setFoodSolutionInput(cleanInput);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (foodSolutionInput.length === 0) {
                  setFoodSubmissionErrorMessage("Please enter a solution");
                } else {
                  checkPuzzleSolution("food-puzzle", foodSolutionInput);
                }
              }
            }}
          ></input>
          {foodSubmissionErrorMessage && (
            <p className="text-error" style={{ marginBottom: "1rem" }}>
              {foodSubmissionErrorMessage}
            </p>
          )}
          {foodIsLoading ? (
            <LoadingWheel />
          ) : !foodPuzzleSolved ? (
            <button
              className="cta"
              disabled={foodSolutionInput.length === 0}
              onClick={() =>
                checkPuzzleSolution("food-puzzle", foodSolutionInput)
              }
            >
              Check solution
            </button>
          ) : (
            <div />
          )}
          {foodPuzzleSolved ? (
            <div className="food-memory-submission text-lower">
              <p className="text-paragraph">
                It's no suprise that, in the years they have been together,
                Fiona and Matt have discovered many interests that support the
                coming-together of their lives.
              </p>
              <p className="text-paragraph">
                Mixing ingredients, swapping recipes, and simply sharing meals
                together around the globe is one way Matt and Fiona have grown
                closer. Food, and the experiences around food they share with
                friends, family, loved ones and strangers, form a central part
                of their lives.
              </p>
              <p className="text-paragraph">
                To help Fiona and Matt mix their lives, please share a food
                recommendation you think they should try. It can be a dish, a
                restaurant, a country, a cuisine, or anything else.
              </p>
              <p className="text-paragraph">
                Be sure to provide a recommendation before continuing with the
                puzzle.
              </p>
              <div style={{ marginTop: "3rem" }}>
                <MemorySubmission
                  memoryLabel="A food:"
                  memoryType="food-memory"
                  memoryCookieName="foodMemorySubmitted"
                />
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <div className="line-bottom" style={{ marginTop: "2rem" }}>
        <button className="cta" onClick={() => navigate("/book-puzzle")}>
          Book Puzzle ▶
        </button>
        <div style={{ marginTop: "2rem" }}>
          Solution:
          <input
            type="text"
            disabled={bookPuzzleSolved}
            value={bookSolutionInput}
            onInput={(e) => {
              const cleanInput = e.target.value.toUpperCase();
              setBookSolutionInput(cleanInput);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (bookSolutionInput.length === 0) {
                  setBookSubmissionErrorMessage("Please enter a solution");
                } else {
                  checkPuzzleSolution("book-puzzle", bookSolutionInput);
                }
              }
            }}
          ></input>
          {bookSubmissionErrorMessage && (
            <p className="text-error" style={{ marginBottom: "1rem" }}>
              {bookSubmissionErrorMessage}
            </p>
          )}
          {bookIsLoading ? (
            <LoadingWheel />
          ) : !bookPuzzleSolved ? (
            <button
              className="cta"
              disabled={bookSolutionInput.length === 0}
              onClick={() =>
                checkPuzzleSolution("book-puzzle", bookSolutionInput)
              }
            >
              Check solution
            </button>
          ) : (
            <div />
          )}
          {bookPuzzleSolved ? (
            <div className="book-memory-submission text-lower">
              <p className="text-paragraph">
                Of all their shared interests, few things have been more central
                to Fiona and Matt's lives than reading. They both love that
                reading the same books as someone else is a way of being
                together.
              </p>
              <p className="text-paragraph">
                No trip is complete without a book in their bags. Whether they
                are going out to the park for a sunny Sunday, jumping on the
                tube to whisk across the city, or flying to the far reaches of
                the world, Fiona and Matt always give a thought to what stories
                they hope to share with the other.
              </p>
              <p className="text-paragraph">
                To help Fiona and Matt share fresh stories, please provide a
                book recommendation you think they should read. It can be
                something meaningful to you, something you think is important
                for newlyweds, or just something you think they would enjoy.
              </p>
              <p className="text-paragraph">
                Be sure to provide a recommendation before continuing with the
                puzzle.
              </p>
              <div style={{ marginTop: "3rem" }}>
                <MemorySubmission
                  memoryLabel="A book:"
                  memoryType="book-memory"
                  memoryCookieName="bookMemorySubmitted"
                />
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
}
