export default function Invite() {
  return (
    <div className="text-container row-top row-bottom text-center">
      <p>Invite you to celebrate their wedding</p>
      <p className="text-italic">
        24<sup className="text-super">th</sup>{" "}
        <span className="text-lower">of</span> May 2025
      </p>
      <p>At Two O'Clock In The Afternoon</p>
      <p className="text-italic">Fasque Castle</p>
      <p>Aberdeenshire, Scotland</p>
    </div>
  );
}
