export default function AccommodationPage() {
  return (
    <main className="line-top line-bottom">
      <div className="main-inner text-center">
        <div className="row-top row-bottom">
          <div className="text-container">
            <h2 className="text-italic">Where to Stay</h2>
            <p className="text-lower">
              Matt and Fiona are booking group accommodation for Friday and
              Saturday night, and will be in touch with guests directly with
              more details. Before booking anything, please reach out to us to
              let us know.
            </p>
            <br></br>
            <h2>Getting to Fasque from Aberdeen</h2>
            <p className="text-lower">
              Local day guests can drive to the venue, and there will be plenty
              of parking available.
            </p>
            <br></br>
            <h2>Getting to Fasque from London</h2>
            <p className="text-lower">
              We recommend that you fly to Aberdeen aiport (ABZ) and then take a
              50 min cab ride to Fasque. Note that rideshare apps (Uber, Lyft,
              etc.) do not operate in Aberdeen.
            </p>
            <br></br>
            <h2>Getting to Fasque from Edinburgh</h2>
            <p className="text-lower">
              We recommend you take the train to Montrose and then take a 30 min
              cab to Fasque. Note that we have no idea if rideshare apps operate
              in Montrose.
            </p>
            <br></br>
            <h2>Getting to Fasque from the US</h2>
            <p className="text-lower">
              The easiest way to get to Fasque from the US is to go through
              either London or Edinburgh. If your schedule allows you to come to
              the UK early, see the <a href="/uk-trip">UK Trip</a> tab for
              further recommendations on where to be.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
