export default function WeddingDayPage() {
  return (
    <main className="line-top line-bottom">
      <div className="main-inner text-center">
        <div className="row-top row-bottom">
          <div className="text-container">
            <h2 className="text-italic">Wedding Day</h2>
            <p className="text-lower">
              The wedding will be held on Saturday, 24th May 2025.
            </p>
            <ul style={{ listStyleType: "none", marginTop: "3.5rem" }}>
              <li>Arrivals starting: 1pm</li>
              <li>Seated: 1.45pm</li>
              <li>Ceremony: 2pm</li>
              <li>Drinks and canapes: 2.30pm</li>
              <li>Speeches: 4.30pm</li>
              <li>Dinner: 5pm</li>
              <li>Dancing: 7pm</li>
              <li>Late food: 9pm</li>
              <li>Home time: midnight</li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}
