import React, { useState, useEffect } from "react";
import dotAnswers from "./dotAnswers.json";
import colorOverrides from "./colorOverrides.json";
import LoadingWheel from "../components/loadingWheel";
import TripleInput from "./tripleInput";
import { ApiService } from "../services/apiService";

export default function ColorGrid() {
  const [reverseColorOverrides, setReverseColorOverrides] = useState({});
  const [colorOverride, setColorOverride] = useState("#000000");

  const [buttonClicks, setButtonClicks] = useState({});
  const [dotsHistory, setDotsHistory] = useState([]);

  const [hoveredButton, setHoveredButton] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const DOTS_JSON_DATA = dotAnswers;

  const CLICKS_BEFORE_TOOL_SHOWS = 4;

  useEffect(() => {
    const persistedButtonClicks = localStorage.getItem("buttonClicks");
    if (persistedButtonClicks) {
      setButtonClicks(JSON.parse(persistedButtonClicks));
    }

    const persistedDotsHistory = localStorage.getItem("dotsHistory");
    if (persistedDotsHistory) {
      const parsedDotsHistory = JSON.parse(persistedDotsHistory);
      setDotsHistory(parsedDotsHistory);
      renderDotHistory(parsedDotsHistory);
    }
  }, []);

  const generateColorsForGrid = (numColors) => {
    const colors = [];
    const step = Math.floor(16777215 / (numColors - 1));
    for (let i = 0; i < numColors; i++) {
      let color = `#${(step * i).toString(16).padStart(6, "0")}`;
      colors.push(color);
    }
    for (let color in colorOverrides) {
      var index = colors.indexOf(color);
      if (index !== -1) {
        colors[index] = colorOverrides[color];
      }
    }
    return colors.sort();
  };

  const generateReverseColorOverrides = () => {
    const reverseColorOverrides = {};
    for (let key in colorOverrides) {
      const value = colorOverrides[key];
      reverseColorOverrides[value] = key;
    }
    setReverseColorOverrides(reverseColorOverrides);
  };

  const updateDotTracking = (color, addOrRemove) => {
    // Our answer JSON always uses lowercase hex values.
    color = color.toLowerCase();

    let updatedDotsHistory = dotsHistory;
    if (addOrRemove === "add") {
      updatedDotsHistory = generateDots(color);
    } else {
      updatedDotsHistory = popLastDotOfColor(color);
    }

    localStorage.setItem("dotsHistory", JSON.stringify(updatedDotsHistory));

    renderDotHistory(updatedDotsHistory);
  };

  const renderDotHistory = (dotsToRender) => {
    const canvas = document.getElementById("bookCanvas");
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    dotsToRender.forEach((dotHistoryEntry) => {
      dotHistoryEntry.dots.forEach((dot) => {
        ctx.fillStyle = dotHistoryEntry.color;
        ctx.beginPath();
        ctx.arc(dot.x, dot.y, dot.radius, 0, 2 * Math.PI);
        ctx.fill();
      });
    });
  };

  const popLastDotOfColor = (color) => {
    const lastDotHistoryEntryIdx = dotsHistory.findLastIndex(
      (dotHistoryEntry) => dotHistoryEntry.color === color
    );
    if (lastDotHistoryEntryIdx > -1) {
      let updatedDotsHistory = dotsHistory;
      updatedDotsHistory.splice(lastDotHistoryEntryIdx, 1);
      setDotsHistory(updatedDotsHistory);
      return updatedDotsHistory;
    }

    return dotsHistory;
  };

  const generateDots = (color) => {
    const canvas = document.getElementById("bookCanvas");
    const dotRadius = 3;
    const idxOfNextClick = buttonClicks[color] || 0;
    let displayColor = color;

    // Create a random set of dots to draw, as a fallback if we don't have the dots in our JSON.
    let dots = Array.from({ length: 500 }, () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      radius: dotRadius,
    }));

    if (DOTS_JSON_DATA[color] && DOTS_JSON_DATA[color][idxOfNextClick]) {
      generateReverseColorOverrides();
      if (reverseColorOverrides[color]) {
        displayColor = reverseColorOverrides[color];
      }

      dots = DOTS_JSON_DATA[color][idxOfNextClick].map((dot) => ({
        x: dot.x,
        y: dot.y,
        radius: dotRadius,
      }));
    }

    const dotHistoryEntry = {
      dots: dots,
      color: displayColor,
    };
    let updatedDotsHistory = dotsHistory;
    updatedDotsHistory.push(dotHistoryEntry);
    setDotsHistory(updatedDotsHistory);

    return updatedDotsHistory;
  };

  const clearCanvas = () => {
    const canvas = document.getElementById("bookCanvas");
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setButtonClicks({});
    setDotsHistory([]);
    setErrorMessage("");

    localStorage.removeItem("buttonClicks");
    localStorage.removeItem("dotsHistory");
  };

  const submitAnswer = () => {
    setErrorMessage("");
    setIsLoading(true);
    ApiService.checkBookSolution(buttonClicks).then((result) => {
      setIsLoading(false);
      setErrorMessage(result.message);
      if (result.status === "correct") {
        console.log("book puzzle correct");
      }
    });
  };

  const hexToRgb = (color) => {
    const hex = color.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const rgbToHex = (rgb) => {
    const hexR = parseInt(rgb[0]).toString(16).padStart(2, "0");
    const hexG = parseInt(rgb[1]).toString(16).padStart(2, "0");
    const hexB = parseInt(rgb[2]).toString(16).padStart(2, "0");
    return `#${hexR}${hexG}${hexB}`;
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "20px",
        }}
      >
        <div className="canvas-container">
          <canvas
            id="bookCanvas"
            width="530"
            height="775"
            style={{ border: "1px solid black", backgroundColor: "white" }}
          ></canvas>
        </div>
        <div
          className="color-grid-container"
          style={{ display: "flex", marginTop: "50px" }}
        >
          <div
            className="color-grid"
            style={{
              display: "grid",
              alignContent: "center",
              gridTemplateColumns: "repeat(20, 1fr)",
              gap: "1px",
              marginTop: "5px",
            }}
          >
            {generateColorsForGrid(500).map((color, index) => (
              <button
                key={index}
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: color,
                  position: "relative",
                }}
                onMouseEnter={() => setHoveredButton(index)}
                onMouseLeave={() => setHoveredButton(null)}
                onClick={() => {
                  setButtonClicks((prevButtonClicks) => {
                    const updatedButtonClicks = { ...prevButtonClicks };
                    updatedButtonClicks[color] =
                      (updatedButtonClicks[color] || 0) + 1;
                    localStorage.setItem(
                      "buttonClicks",
                      JSON.stringify(updatedButtonClicks)
                    );
                    return updatedButtonClicks;
                  });
                  updateDotTracking(color, "add");
                }}
              >
                {hoveredButton === index && (
                  <div
                    style={{
                      position: "absolute",
                      width: "135px",
                      left: "100%",
                      backgroundColor: "white",
                      border: "1px solid black",
                      padding: "2px",
                      fontSize: "10px",
                      zIndex: 2,
                    }}
                  >
                    {hexToRgb(color)}
                  </div>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="text-container">
        <div
          style={{
            maxWidth: "1000px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {errorMessage}
        </div>
        {isLoading ? <LoadingWheel /> : <div />}
        <button
          className="cta"
          onClick={clearCanvas}
          disabled={Object.keys(buttonClicks).length == 0}
        >
          Clear
        </button>
        <button
          className="cta"
          onClick={submitAnswer}
          disabled={Object.keys(buttonClicks).length == 0}
        >
          Print My Book
        </button>
        <div className="grid-helper">
          {Object.values(buttonClicks).reduce((a, b) => a + b, 0) >
            CLICKS_BEFORE_TOOL_SHOWS && (
            <div>
              Locate colors faster
              <div
                className="text-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <TripleInput
                  onChange={(rgb) => setColorOverride(rgbToHex(rgb))}
                />
                <button
                  className="cta"
                  onClick={() => {
                    setButtonClicks((prevButtonClicks) => {
                      const updatedButtonClicks = { ...prevButtonClicks };
                      updatedButtonClicks[colorOverride] =
                        (updatedButtonClicks[colorOverride] || 0) + 1;
                      localStorage.setItem(
                        "buttonClicks",
                        JSON.stringify(updatedButtonClicks)
                      );
                      return updatedButtonClicks;
                    });
                    updateDotTracking(colorOverride, "add");
                  }}
                >
                  +
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="color-counts" style={{ marginTop: "10px" }}>
          {Object.entries(buttonClicks).map(([color, count]) => (
            <div
              key={color}
              className="color-count-row"
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "8px 0",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: color,
                  marginRight: "8px",
                  border: "1px solid #ccc",
                }}
              />
              <span style={{ marginRight: "8px" }}>
                {hexToRgb(color)}: {count}
              </span>
              <button
                className="cta"
                onClick={() => {
                  setButtonClicks((prevButtonClicks) => {
                    const updatedButtonClicks = { ...prevButtonClicks };
                    updatedButtonClicks[color] =
                      (updatedButtonClicks[color] || 0) + 1;
                    localStorage.setItem(
                      "buttonClicks",
                      JSON.stringify(updatedButtonClicks)
                    );
                    return updatedButtonClicks;
                  });
                  updateDotTracking(color, "add");
                }}
                style={{ marginRight: "4px" }}
              >
                +
              </button>
              <button
                className="cta"
                onClick={() => {
                  setButtonClicks((prevButtonClicks) => {
                    const updatedButtonClicks = { ...prevButtonClicks };
                    updatedButtonClicks[color] = updatedButtonClicks[color] - 1;
                    if (updatedButtonClicks[color] === 0) {
                      delete updatedButtonClicks[color];
                    }
                    localStorage.setItem(
                      "buttonClicks",
                      JSON.stringify(updatedButtonClicks)
                    );
                    return updatedButtonClicks;
                  });
                  updateDotTracking(color, "remove");
                }}
              >
                -
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
