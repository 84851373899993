import { useState, useEffect } from "react";

export default function RecipeDisplay({
  key,
  text,
  emojiString,
  correctIndexes,
}) {
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    setShowOverlay(true);
  }, []);

  const getLetterForIndex = (index) => {
    if (text[index] === " ") {
      return "";
    }
    return text[index];
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="cta"
          onClick={() => setShowOverlay(!showOverlay)}
          style={{
            padding: "5px 10px",
          }}
        >
          {showOverlay ? "Remove" : "Overlay"}
        </button>
      </div>

      <div
        id="recipe"
        style={{
          width: "500px",
          height: "400px",
          display: "grid",
          gridTemplateColumns: "repeat(16, 32px)",
          gridTemplateRows: "repeat(13, 31px)",
          gap: "0px",
        }}
      >
        {Array(13 * 16)
          .fill("")
          .map((_, index) => (
            <div
              key={index}
              style={{
                height: "31px",
                width: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f1e9d7",
                fontSize: "25px",
                color: "black",
              }}
            >
              {getLetterForIndex(index)}
            </div>
          ))}
      </div>
      <div
        style={{
          width: "500px",
          height: "400px",
          opacity: 1,
          border: "1px solid #ddd",
          display: "grid",
          gridTemplateColumns: "repeat(16, 1fr)",
          position: "absolute",
          // 676, 581
          top: showOverlay
            ? document.getElementById("recipe")?.offsetTop - 13 || 0
            : (document.getElementById("recipe")?.offsetTop || 0) * 1.5,
          left: document.getElementById("recipe")?.offsetLeft || 0,
          transition: "top 0.7s ease",
        }}
      >
        {[...emojiString].map((emoji, i) => (
          <div
            key={i}
            style={{
              height: "31px",
              width: "32px",
              backgroundColor: "#f5eedc",
              fontSize: "24px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.target.style.opacity =
                e.target.style.opacity === "0.3" ? "1" : "0.3";
              if (correctIndexes.includes(i)) {
                if (e.target.style.opacity === "0.3") {
                  e.target.style.backgroundColor = "green";
                } else {
                  e.target.style.backgroundColor = "#f5eedc";
                }
              }
            }}
          >
            {showOverlay ? "" : emoji}
          </div>
        ))}
        <div
          style={{
            gridColumn: "1 / -1",
            display: "flex",
            justifyContent: "center",
          }}
        ></div>
      </div>
    </>
  );
}
