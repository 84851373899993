import { app } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(app);

const validateOtp = httpsCallable(functions, "validateOtp");
const createMemory = httpsCallable(functions, "createMemory");
const getParty = httpsCallable(functions, "getParty");
const updateParty = httpsCallable(functions, "updateParty");
const checkBookSolution = httpsCallable(functions, "checkBookSolution");
const checkPuzzleSolution = httpsCallable(functions, "checkPuzzleSolution");

export const ApiService = {
  async validateOtp(code) {
    return validateOtp({ otp: code })
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        return { status: data.status, link: data.link };
      })
      .catch((error) => {
        console.log("Got an error");
        console.error(error.message);
        console.error(error.details);
      });
  },

  async getParty(name) {
    return getParty({ name: name })
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        return {
          id: data.id,
          name: data.name,
          status: data.status,
          features: data.features,
          members: data.members,
        };
      })
      .catch((error) => {
        console.log("Got an error");
        console.error(error.message);
        console.error(error.details);
      });
  },

  async updateParty(party) {
    return updateParty(party)
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        return {
          id: data.id,
          name: data.name,
          status: data.status,
          features: data.features,
          members: data.members,
        };
      })
      .catch((error) => {
        console.log("Got an error");
        console.error(error.message);
        console.error(error.details);
      });
  },

  async createMemory(memory) {
    return createMemory(memory)
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        return { id: data.id };
      })
      .catch((error) => {
        console.log("Got an error");
        console.error(error.message);
        console.error(error.details);
      });
  },

  async checkBookSolution(solution) {
    return checkBookSolution({ solution: solution })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log("Got an error");
        console.error(error.message);
        console.error(error.details);
        return {
          status: "error",
          message: "There was an error checking your solution",
        };
      });
  },

  async checkPuzzleSolution(puzzle, solution) {
    return checkPuzzleSolution({ puzzle: puzzle, solution: solution })
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.log("Got an error");
        console.error(error.message);
        console.error(error.details);
        return {
          status: "error",
          message: "There was an error checking your solution",
        };
      });
  },
};

const fakePartiesTable = [
  {
    id: "1",
    name: "Bob and Linda Bobberson",
    members: [
      { name: "Bob", rsvp_status: "", question_status: "" },
      { name: "Linda", rsvp_status: "", question_status: "" },
    ],
    features: [],
  },
  {
    id: "2",
    name: "Harry and Ginny Potter",
    members: [
      { name: "Harry", rsvp_status: "", question_status: "" },
      { name: "Ginny", rsvp_status: "", question_status: "" },
    ],
    features: [],
  },
  {
    id: "3",
    name: "Dork McLoner",
    members: [{ name: "Dork", rsvp_status: "", question_status: "" }],
    features: ["gotcha"],
  },
];

const DOT_CLICK_ANSWER = {
  "#ddd1b3": 5,
  "#665356": 2,
  "#dfe5b1": 8,
  "#a9847f": 2,
  "#603205": 3,
  "#342643": 1,
  "#d3b387": 5,
  "#a6854e": 4,
  "#8f785d": 5,
  "#efe9c9": 3,
};

const funnyErrorMessages = [
  "Penguin called, they want their random house back! Try again",
  "Jackson Pollock? Is that you? Try again",
  "They say if you give typewriters to an infinite number of monkeys, they will eventually bang out the words of Shakespeare. But you clearly already know this theory. Try again",
  "Do you remember when Dippin' Dots was a thing? I don't know why that just popped into my head...Try again",
  "A lot of people try to solve a puzzle by getting closer and closer to the correct solution. I appreciate your contrarian approach. Try again",
  "If you could go back in time and change one single thing in the world...would it be this submission? Try again",
  "I just feel like you're not taking this seriously. Try again",
  "I suppose in the grand scheme of things, you're not that far off. I'm speaking galactically here. Try again",
  "I didn't know you were studying to be a psychiatrist. You've got the ink blot tests all sorted out. Try again",
  "Wait, let me guess. The Big Dipper? Or, the one that's an archer? Try again",
  "No way, Jose. Try again",
];

export const FakeApiService = {
  async validateOtp(code) {
    if (code === "123456") {
      return { status: "correct", link: "/photo-puzzle" };
    }
    return { status: "incorrect" };
  },

  async getParty(name) {
    for (const entry of fakePartiesTable) {
      if (entry.name.toUpperCase() === name.toUpperCase()) {
        return entry;
      }
    }
    return {};
  },

  async updateParty(party) {
    if (!party || !party.id) {
      return { error: "Invalid request" };
    }
    for (const entry of fakePartiesTable) {
      if (entry.id === party.id) {
        entry.members = party.members;
        return entry;
      }
    }
    return { status: "COMPLETE" };
  },

  async createMemory(memory) {
    if (!memory || !memory.name || !memory.memory) {
      return { error: "Invalid request" };
    }
    return { id: "abc" };
  },

  async checkPuzzleSolution(puzzle, solution) {
    if (!puzzle || !solution) {
      return { status: "invalid request" };
    }

    if (puzzle === "book-puzzle") {
      return solution === "CHARLOTTE'S WEB"
        ? { status: "correct", cookieName: "bookPuzzleSolved" }
        : { status: "incorrect" };
    }

    if (puzzle === "food-puzzle") {
      return solution === "SWISS CHARD"
        ? { status: "correct", cookieName: "foodPuzzleSolved" }
        : { status: "incorrect" };
    }

    return { status: "unknown puzzle" };
  },

  async checkBookSolution(solution) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (!solution) {
      return { status: "invalid request" };
    }

    // Compare each element in solution to dotClickAnswer
    for (const [color, clicks] of Object.entries(DOT_CLICK_ANSWER)) {
      if (solution[color] !== clicks) {
        // Return random error message if mismatch
        const randomIndex = Math.floor(
          Math.random() * funnyErrorMessages.length
        );
        return {
          status: "incorrect",
          message: funnyErrorMessages[randomIndex] || "Try again!",
        };
      }
    }

    return {
      status: "correct",
      message: "You did it! The puzzle solution is: CHARLOTTE'S WEB",
      cookieName: "bookPuzzleSolved",
    };
  },
};
