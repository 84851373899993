import { useState } from "react";
import PartOne from "../components/puzzle_central/partOne";
import PartTwo from "../components/puzzle_central/partTwo";
import PartThree from "../components/puzzle_central/partThree";
import LineSVG from "../assets/svg/line";

/* Dear Puzzle Solver, viewing these hidden routes is not the intended way to solve the puzzle. 
If you view these hidden pages, you will likely spoil the solutions. If you wish to solve the puzzle the intended 
way, take a close look at the save the date picture we sent, as that will lead you through to these pages organically.*/
export default function PuzzleCentralPage() {
  const [partTwoSolved, setPartTwoSolved] = useState(
    localStorage.getItem("partTwoSolved") || false
  );

  const onSolved = () => {
    localStorage.setItem("partTwoSolved", true);
    setPartTwoSolved(true);
  };

  return (
    <main className="line-top line-bottom">
      <div className="main-inner text-center">
        <div className="row-bottom">
          <div className="text-container">
            <div>
              <h2 className="text-italic">A story of love and adventure</h2>
              <div>
                <p className="text-lower">
                  <br></br>
                  <a href="/❤️">FionaAndMatt.com/❤️</a>
                </p>
              </div>
            </div>
            <LineSVG />
            <PartOne />
            <LineSVG />
            <PartTwo onSolved={onSolved} />
            <LineSVG />
            <PartThree displayPartThree={partTwoSolved} />
          </div>
        </div>
      </div>
    </main>
  );
}
