import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import ColorGrid from "../components/colorGrid";
import bookPuzzle from "../assets/book_puzzle/book_puzzle.png";

export default function BookPuzzlePage() {
  const navigate = useNavigate();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="book-puzzle-page">
      <main className="line-top">
        <div className="main-inner text-center">
          <div className="row-top row-bottom">
            <div className="text-container">
              <h2 className="text-italic">Book Puzzle</h2>
              <button className="cta" onClick={() => navigate("/❤️#part-ii")}>
                ←
              </button>
              <img
                src={bookPuzzle}
                alt="Book Puzzle"
                onClick={() => window.open(bookPuzzle, "_blank")}
                style={{ cursor: "zoom-in" }}
              />
              <div>
                <div className="text-container text-lower line-top line-bottom">
                  The news had been building for a while, yet everyone was still
                  shocked and frightened by the idea that the Dark Lord had
                  returned. Professor Malcolm's warning, that life finds a way,
                  was true after all.
                </div>
                <div className="text-container text-lower line-top line-bottom">
                  I remember my brother destroying those camellia bushes. At
                  first he was using my baton, but later, he was just grabbing
                  pigtails and hurling the animals into the flowers.
                </div>
                <div className="text-container text-lower line-top line-bottom">
                  There was much for the children to discover in this mysterious
                  new land. There were biblical creatures, and a few phonies as
                  well.
                </div>
                <div className="text-container text-lower line-top line-bottom">
                  Car accidents happen, and often nobody is to blame. But
                  sometimes your emotions can be blamed. Speeding away from a
                  poor performance of Shakespear? That seems blameworthy.
                </div>
                <div className="text-container text-lower line-top line-bottom">
                  It was a dream farm, one that you could escape to when things
                  got hard. It had a little house and a couple of acres, a
                  weirdly spacious wardrobe, all very meaningful.
                </div>
                <div className="text-container text-lower line-top line-bottom">
                  There were bright lights all around, pulling the party guests
                  every which way. A dormouse and his behatted friend were
                  enraptured by the green light, while others chased colors of
                  their own.
                </div>
                <div className="text-container text-lower line-top line-bottom">
                  It was an easy thing to overlook, that the auxiliary
                  electricity would be used by default after they restarted the
                  system to clear out the virus. It was the kind of mistake that
                  anyone could make: those who have been around for years, and
                  those who are perpetually young.
                </div>
                <div className="text-container text-lower line-top line-bottom">
                  You can shoot all the bluejays you want, but it's a sin to
                  take an owl out of the sky.
                </div>
                <div className="text-container text-lower line-top line-bottom">
                  Prep School was not always a fun place. Sometimes you might
                  flee to New York City, because of the bullies, or because you
                  miss honey.
                </div>
                <div className="text-container text-lower line-top line-bottom">
                  Lennie loved to pet soft animals. The animals didn't always
                  enjoy the experience, so Lennie would often see a cat without
                  a grin. But a grin without a cat? That was something brand
                  new.
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div
        className="box-container"
        style={{ width: "fit-content", margin: "0 auto" }}
      >
        <ColorGrid />
      </div>
    </div>
  );
}
