import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RecipeDisplay from "../components/recipeDisplay";

export default function FoodPuzzlePage() {
  const navigate = useNavigate();

  const [recipeIndex, setRecipeIndex] = useState(0);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RECIPES = [
    {
      text: "Stir or whisk in a vessel of    some kind the   parts for both a tall stack of  syrup-receivers and also that   traditional     Southern USA    side that's kind of the outer   part of a       hushpuppy",
      correctIndexes: [0, 8, 90, 128, 154],
      emojiString:
        "🍳🥛🧅🍷🍣🍚🍪🍾🍰🍫🫕🍣🍠🍶🍈🧈🧀🥚🍒🍿🍘🍆🍟🍍🍿🍅🍖🍈🍅🍎🍛🍥🧄🥒🍢🍓🫛🫕🫒🥭🥬🍅🧀🍯🥚🍑🥙🍤🍼🍫🥡🍪🥦🍒🫖🥒🍾🥭🫕🥕🥜🥙🍄🍷🍅🍙🫔🍤🍈🍤🫒🍎🍏🍄🫖🥠🥜🍎🍏🥠🥣🍑🫒🍱🍬🍤🍓🥯🥛🍏🥞🧀🍾🧅🥚🥫🫛🍑🍭🍛🍿🍅🍕🍎🥬🍿🌭🥟🍑🌯🥥🥯🍜🍿🍥🫒🥯🫚🫖🍡🍜🍑🫐🍮🍙🧂🍅🍊🌽🍙🍷🍍🥑🫓🍩🫐🫓🫘🥩🧄🍐🌯🫑🥝🍓🍱🍐🍘🥔🍛🥣🧇🥟🫖🍞🫘🫚🫐🍓🍝🍔🍔🍧🫘🥠🍷🍮🥠🥡🥑🍆🫒🍫🫑🧅🍛🍯🍝🧂🥟🫓🫘🍍🍘🍷🌮🍍🧈🫖🍭🥡🍒🍣🧅🍒🍌🫕🍗🍧🌮🌭🌭🥝🍘🍦🥜🍊🍑",
    },
    {
      text: "Half a cup of   artful images of the parts to   the worst melon and also the    candy that's the flavor of Werther's Original   (also sometimes called the      flavour of The  Queen's         Sweetmeat)",
      correctIndexes: [7, 49, 81, 109, 149],
      emojiString:
        "🍡🍐🍩🍥🧆🥘🍶🍯🥕🍜🫔🥗🍐🫖🥦🥒🥝🍊🍿🫓🍵🌭🍱🥙🥭🍅🍟🍼🥗🍒🫑🍑🫐🫘🍍🥬🍌🥑🍼🍫🍊🥒🌮🍛🍪🍮🍥🍮🍘💦🍧🥜🍉🍘🌭🥕🍭🍇🍸🍜🥡🧄🍇🥑🍟🍡🍭🥩🥦🥚🍸🍠🧅☕🍙🍬🌰🍵🧅🍌🍡🍈🥜🍚🧆🍧🥛🍊🍷🍇🫘🍼🍒🥦🥯🍧🍣🥒🫒🍱🍬🍪🍌🧇🥩🥚🍣🍼🧇🧈🍨🍌🥡🥗🍔🫓🥠🥚🍬🌮🫖🧂🍌🍚🥯🍧🥝🍿🥕🍭🍏🍑🍓🍤🥠🍎🌭🍬🌮🥡🍉🥣🧄🍑🫐🍜🥒🍼🫔🥃🥩🍔🥔🧂🥔🫑🍙🍌🥫🥥🥚🍣🍖🍪🍇🧅🥬🍱🍇🍍🍡🍾🥝🍥🫓🍋🧅🥜🥣🍦🍢🍘🧆🍥🍓🫛🍉🍚🥩🍠🧂🌰🍶🫘🍣🍅🥡🍤🍭🥗🍜🫚🍎🥗🌮🍒🥫🍣",
    },
  ];

  const changeRecipe = (newIndex) => {
    setRecipeIndex(newIndex);
  };

  return (
    <main className="line-top line-bottom">
      <div className="main-inner text-center">
        <div className="row-top row-bottom">
          <div className="text-container" style={{ height: "1300px" }}>
            <h2 className="text-italic">Food Puzzle</h2>
            <button className="cta" onClick={() => navigate("/❤️#part-ii")}>
              ←
            </button>
            <div style={{ marginTop: "3rem" }}>
              <span>Recipe {recipeIndex + 1}</span>
              <div>
                <button
                  className="cta"
                  onClick={() => changeRecipe(recipeIndex - 1)}
                  disabled={recipeIndex === 0}
                >
                  {"<"}
                </button>
                <button
                  className="cta"
                  onClick={() => changeRecipe(recipeIndex + 1)}
                  disabled={recipeIndex === RECIPES.length - 1}
                >
                  {">"}
                </button>
              </div>
            </div>
            <div style={{ marginTop: "3rem" }}>
              <p className="text-lower">First ingredient: 2 parts, 1 whole</p>
              <p className="text-lower">Second ingredient: 2 parts</p>
            </div>
            <RecipeDisplay
              key={recipeIndex}
              text={RECIPES[recipeIndex].text}
              emojiString={RECIPES[recipeIndex].emojiString}
              correctIndexes={RECIPES[recipeIndex].correctIndexes}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
