import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DragDropTable from "../components/dragDropTable";

export default function MixedPuzzlePage() {
  const navigate = useNavigate();

  const [isSolved, setIsSolved] = useState(
    localStorage.getItem("mixedPuzzleSolved") ? true : false
  );

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);

    // localStorage.removeItem("mixedPuzzleSolved");
  }, []);

  return (
    <div>
      <main className="line-top">
        <div className="main-inner text-center">
          <div className="row-top row-bottom">
            <div className="text-container">
              <h2 className="text-italic">Winning Combo</h2>
              <button className="cta" onClick={() => navigate("/❤️#part-ii")}>
                ←
              </button>
            </div>
          </div>
        </div>
      </main>
      <div
        className="box-container"
        style={{ width: "fit-content", margin: "0 auto" }}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DragDropTable
            onProgress={(remaining) => {
              if (remaining === 0) {
                setIsSolved(true);
                localStorage.setItem("mixedPuzzleSolved", true);
              }
            }}
            isSolved={isSolved}
          />
        </div>
        {isSolved && (
          <div
            style={{
              marginTop: "20px",
              fontSize: "24px",
              textAlign: "center",
            }}
          >
            <div>Congratulations!</div>
            <p className="text-paragraph">
              Head back to the main puzzle page to finish the story.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
